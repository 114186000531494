export default class ListeVehicules extends HTMLDivElement {

    constructor() {
        super();
        this.PageVehiculeUrl = $(this).data('page-vehicule-url');
    }

    connectedCallback() {

        var self = this;

        // on g�re le changement sur le select des th�mes
        $(".js-marque-select").on('change', function (e) {

            if (this.value === "all") {
                // on va rediriger vers la bonne page
                window.location.href = "/" + self.PageVehiculeUrl;
            } else {
                // on va rediriger vers la bonne page
                window.location.href = "/" + self.PageVehiculeUrl + "/" + this.value;
            }
        });

        // on g�re le changement sur le select des motorisations
        $(".js-motorisation-select").on('change', function (e) {

            let motorisationId = this.value

            $(".js-vehicle").each(function () {
                // Si jamais on a choisit toute motorisation ou que la motorisation est la meme que le vehicule et que la marque est la meme que celle selectionne
                //ou alors que toutes marque soit selectionne on affiche la carte
                if (((motorisationId == "all" || motorisationId == $(this).data().motorisation)
                    && $(this).data().marque == $(".js-marque-select").val())
                    || $(".js-marque-select").val() == "all") {
                    $(this).show()
                }
            });

            if (motorisationId != "all") {
                $(".js-vehicle").each(function () {
                    if ($(this).data().motorisation != motorisationId) {
                        $(this).hide()
                    }
                });
            }
        });
    }

}