class Modal {

    constructor() {
        this.bind();
    }

    bind() {

        let self = this;

        window.OnFailure = this.OnFailure;
        window.OnSuccess = this.OnSuccess;

        $(document).on('click', '[data-modal-url]', (e) => {
            e.preventDefault();

            let url = $(e.currentTarget).data('modal-url');
            let target = $(e.currentTarget).data('modal-target');
            let modalClass = $(e.currentTarget).data('modal-class') || '';
            this.open(url, target, modalClass);

            $(e.currentTarget).addClass('active');
        });


        // Afin d'ouvrir la modale en automatique dans certain cas
        $(function () {
            // Test ici la pr�sence de l'ancre #devis dans l'url'
            var hash = window.location.hash;
            if (hash === "#devis") {

                // on r�cup�re les info pour ouvrir la modale
                let $devisButton = $("#ask-for-devis-modal");

                // Si les infos sont bien pr�sente on ouvre la modale
                if ($devisButton.length > 0) {
                    let url = $devisButton.data('modal-url');
                    let target = $devisButton.data('modal-target');
                    let modalClass = $devisButton.data('modal-class') || '';
                    self.open(url, target, modalClass);

                    $devisButton.addClass('active');
                }
            }
        });
       

        $(document).on('click', '.close-popup-modal', (e, data) => {
            console.log("test");
            this.close();
        });
    }

    open(url, target, modalClass, data, methodAjax, modalClassForBackdrop) {

        this.close(true);

        let $target = $('body');

        if (target !== undefined && $(target).length !== 0) {
            $target = $(target)
        }

        if (methodAjax == undefined || methodAjax == null) {
            methodAjax = "GET";
        }

        if (methodAjax != 'GET' && methodAjax != 'POST') {
            methodAjax = "GET";
        }

        $.ajax({
            url: url,
            method: methodAjax,
            data: (data !== null) ? { data: data } : null
        }).done((data) => {

            let $modal = $('<div data-url="' + url + '" class="popup-modal ' + modalClass + '"></div>');
            let $backdrop = $('<div class="popup-backdrop ' + modalClassForBackdrop + '"></div>');

            $backdrop.on('click', (e) => {
                if (e.target !== e.currentTarget) return;
                this.close();
            });

            $modal.append($(data));
            $backdrop.append($modal);
            $target.append($backdrop);

            App.Kernel.bindComponents($modal);

            $('.btn-close', $modal).on('click', () => {
                this.close();
            });
        });

    }

    close(forPrevious = false) {
        $('a[data-modal-url]').removeClass('active');
        $('a[data-modal-url]').removeClass('highlightRed');

        let $backdrop = $('body').find('.popup-backdrop');
        $backdrop.remove();

        // on est dans le tunnel et on veut remettre le bouton avec sa bonne valeur
        if (!forPrevious && $('.js-loading') && $('.valueOfBtnBeforeLoading')) {
            let parent = $('.js-loading').parent();
            if (parent[0]) {
                $('.js-loading').remove();
                parent.append(parent.parent().find('.valueOfBtnBeforeLoading').html());
                parent[0].disabled = false;
            }
        }
    }

    OnFailure(response) {
        $('body').find('.modal-content').html(response);
    }

    OnSuccess(response) {
        if (response.success != undefined && response.success == 1) {
            location.href = response.url;
        }

        $('body').find('.modal-content').html(response);
    }
}

module.exports = Modal;