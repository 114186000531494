export default class ListeActualites extends HTMLDivElement {

    constructor() {
        super();
        this.PageActualiteUrl = $(this).data('page-actualite-url');
    }

    connectedCallback() {

        var self = this;

        // on g�re le changement sur le select des th�mes
        $(".js-theme-select").on('change', function (e) {

            if (this.value === "all") {
                // on va rediriger vers la bonne page 
                window.location.href = "/" + self.PageActualiteUrl;
            } else {
                // on va rediriger vers la bonne page 
                window.location.href = "/" + self.PageActualiteUrl + "/theme/" + this.value;
            }
        }); 
    }
}