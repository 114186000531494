export default class CustomHeader extends HTMLElement {

    constructor() {
        super();
        this.toggleNavBtn = this.querySelector('.js-toggle-nav');
        this.nav = this.querySelector('.js-nav');
        this.navOpened = false;
        this.navItemsWithChildren = this.querySelectorAll('.js-has-child > a');
    }

    connectedCallback() {

        this.toggleNavBtn.addEventListener('click', (e) => {
            this.toggleNav();
        })

        this.adjustNavPosition();

        window.addEventListener('resize', (e) => {
            this.adjustNavPosition();
        })

        for (const navItemWithChildren of this.navItemsWithChildren) {
            navItemWithChildren.addEventListener('click', (e) => {
                navItemWithChildren.parentElement.classList.toggle('opened');
            });
        }

    }

    adjustNavPosition() {
        if (window.innerWidth < 1200) {
            let top = this.getBoundingClientRect().bottom - 1;
            console.log(Math.round(top));
            this.nav.style.top = Math.round(top) + "px";
        } else {
            this.nav.style.top = "auto";
            this.classList.remove('nav-opened');
            document.body.classList.remove('no-scroll');
        }
    }

    toggleNav() {
        if (this.navOpened === true) {
            this.classList.remove('nav-opened');
            document.documentElement.classList.remove('no-scroll');
            this.navOpened = false;
        } else {
            this.classList.add('nav-opened');
            document.documentElement.classList.add('no-scroll');
            this.navOpened = true;
        }
    }

}