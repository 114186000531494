export default class Bot {

    constructor() {
        this.state = null;
    }

    getNextQuestion(state) {
        
        this.state = state;

        switch (this.state.length) {
            case 0:
                this.ask("message", 1, "Bonjour ! Je suis Benoît, expert Les Formules. Je vous propose de faire le point sur vos déplacements professionnels.");
                break;
            case 1:
                this.ask("location", 1, "Où se trouve votre entreprise ?", "Address");
                break;
            case 2:
                this.ask("message", 2, "Merci !");
                break;
            case 3:
                this.ask("number", 2, "Parlons des déplacements de vos collaborateurs. Combien de personnes sont concernées ?", "NbUser");
                break;
            case 4:
                this.ask("choice", 2, "Pour des déplacements en...", "VehicleType", [
                    { text: "Voitures", value: 1 },
                    { text: "Camions", value: 2 },
                    { text: "Voitures & camions", value: 3 }
                ]);
                break;
            case 5:
                this.ask("choice", 2, "Vos collaborateurs ont besoin de se déplacer...", "RhythmType", [
                    { text: "Tous les jours ou presque", value: 1 },
                    { text: "Plusieurs fois par mois", value: 2 },
                    { text: "Occasionnellement", value: 3 }
                ]);
                break;
            case 6:
                this.ask("choice", 2, "Quelle est la durée de leurs trajets en général ?", "DurationType", [
                    { text: "À la demi-journée", value: 1 },
                    { text: "À la journée", value: 2 },
                    { text: "Moins d'une semaine", value: 3 },
                    { text: "Moins d'un mois", value: 4 },
                    { text: "Davantage", value: 5 }
                ]);
                break;
            case 7:
                this.ask("choice", 2, "Actuellement, les collaborateurs se déplacent avec :", "PastSolutionType", [
                    { text: "des véhicules appartenant à l'entreprise", value: 1 },
                    { text: "des véhicules en location", value: 2 },
                    { text: "leur voiture personnelle", value: 3 }
                ]);
                break;
            case 8:
                this.ask("form", 3, "Parfait ! Laissez-moi maintenant vos coordonnées pour que je puisse vous faire une proposition.");
                break;
            case 9:
                this.ask("message", 4, "Merci. Je vais maintenant étudier vos besoins avec mon équipe. Nous revenons vers vous sous 2 heures max avec LA bonne formule !");
                break;
            case 10:
                this.ask("message", 4, "À tout à l’heure !", null, null, true);
                break;
            default:
                break;
        }

        return this.state;

    }

    ask(type = null, step = null, label = null, field = null, options = null, last = false) {

        this.state.push({
            type: type,
            step: step,
            label: label,
            options: options,
            last: last,
            field: field
        });

    }

}