import Bot from "./Bot";
import AddressAutocomplete from "./AddressAutocomplete";

export default class ChatBot extends HTMLDivElement {

    constructor() {
        super();
        this.$view = $(this);
        this.bot = new Bot();
        this.$conversation = this.$view.find('.js-conversation');
        this.$cta = this.$view.find('.js-cta');
        this.$steps = this.$view.find('.js-steps');
        this.$thumb = this.$view.find('.js-thumb');
        this.state = [];
    }

    connectedCallback() {
        this.getNextQuestion();
    }

    getNextQuestion() {
        this.state = this.bot.getNextQuestion(this.state);
        this.renderQuestion();
    }

    setAnswer(answer, displayValue = null) {
        displayValue = displayValue !== null ? displayValue : answer;
        this.state[this.state.length - 1].answer = { text: displayValue, value: answer };
        if (answer !== null) {
            this.renderAnswer();
            this.updateThumbPosition();
        }
        this.$cta.children().fadeOut();

        setTimeout(() => {
            this.getNextQuestion();
        }, 500);
    }

    renderQuestion() {
        const lastQuestion = this.state[this.state.length - 1];

        let $loader = $('<div class="question">•••</div>');
        let $question = $('<div class="question">' + lastQuestion.label + '</div>');

        this.$conversation.append($loader);
        this.updateThumbPosition();

        setTimeout(() => {
            $loader.fadeOut(300, () => {

                // Si la question est de type form, on vide la conversation pour n'afficher que la dernière question
                if (lastQuestion.type === "form") {
                    this.$conversation.html('');
                }

                this.$conversation.append($question);
                this.updateStep(lastQuestion.step);
            });

            setTimeout(() => {
                if (lastQuestion.type === "message" && lastQuestion.last === false) {
                    this.getNextQuestion();
                } else {
                    this.renderCta();
                }
            }, 500);

        }, 1000);

    }

    renderAnswer() {
        const lastQuestion = this.state[this.state.length - 1];
        this.$conversation.append('<div class="answer">' + lastQuestion.answer.text + '</div>');
    }

    updateStep(step) {

        this.$steps.find('li').each((i, e) => {
            if ((i + 1) < step) {
                $(e).removeClass('active');
                $(e).addClass('completed');
            }
            if ((i + 1) === step) {
                $(e).addClass('active');
            }
        });

    }

    renderCta() {
        const lastQuestion = this.state[this.state.length - 1];

        switch (lastQuestion.type) {
            case "number":
                this.renderNumberCta();
                break;
            case "location":
                this.renderLocationCta();
                break;
            case "choice":
                this.renderChoiceCta();
                break;
            case "form":
                this.renderFormCta();
                break;
        }
    }

    renderNumberCta() {
        let $form = $('<form class="cta-form"><input type="number" placeholder="Saisir un nombre" class="form-control" name="value" required="required" /><button type="submit"><i class="basic-pictoarrow-up"></i></button></form>');
        this.$cta.html($form);
        $form.find('input').focus();

        $form.on('submit', (e) => {
            e.preventDefault();
            this.setAnswer($(e.currentTarget).find("input").val());
        });
    }

    renderLocationCta() {
        let $form = $('<form class="cta-form cta-form-location">' +
            '    <input type="text" placeholder="Saisir le code postal ou la ville" class="form-control js-input" name="value" required="required" />' +
            '    <button type="submit"><i class="basic-pictoarrow-up"></i></button>' +
            '</form>');

        let ac = new AddressAutocomplete($form);

        this.$cta.html($form);
        $form.find('input').focus();

        $form.on('submit', (e) => {
            e.preventDefault();
            this.setAnswer($(e.currentTarget).find("input").val());
        });
    }

    renderChoiceCta() {
        const lastQuestion = this.state[this.state.length - 1];

        let $list = $('<ul class="cta-list"></ul>');
        for (let i = 0; i < lastQuestion.options.length; i++) {
            let $listItem = $('<li data-val="' + lastQuestion.options[i].value + '">' + lastQuestion.options[i].text + '</li>');
            $listItem.on('click', (e) => {
                this.setAnswer($(e.currentTarget).data('val'), $(e.currentTarget).html());
            });
            $list.append($listItem);
        }
        this.$cta.html($list);
    }

    renderFormCta() {

        var partOneObject = {};
        for (var i = 0; i < this.state.length; i++) {
            if (this.state[i].field !== null) {
                partOneObject = { ...partOneObject, [this.state[i].field]: this.state[i].answer.value };
            }
        }

        $.ajax({
            url: "/ChatBot/GetCoordForm",
            type: "POST",
            contentType: 'application/json',
            dataType: "html",
            data: JSON.stringify(partOneObject)
        }).done((data) => {
            let $html = $(data);
            this.$view.addClass('expanded');
            this.$cta.html($html);

            App.Kernel.bindComponents($("body"));
        });

    }

    updateThumbPosition() {
        let conversationHeight = this.$conversation.height();
        let windowHeight = window.innerHeight;

        if (conversationHeight < (windowHeight - 190)) {
            this.$thumb.css('top', 'auto');
            this.$thumb.css('bottom', conversationHeight + 40);
        } else {
            this.$thumb.css('top', 32);
            this.$thumb.css('bottom', 'auto');
        }
    }

}
