export default class AddressAutocomplete {

    constructor($view) {

        this.$view = $view;
        this.$input = $view.find('.js-input');
        this.bind();
    }

    bind() {
        let complete = false;

        this.$input.on('blur', (e) => {
            if (!complete) {
                this.$input.val('')
            }
            this.$view.find(".js-autocomplete").remove();
        });

        this.$input.on('keypress', (e) => {
            if (e.key === "Enter" && !complete) {
                e.preventDefault();
                this.$input.blur();
            }
        })

        this.$input.on('keyup', (e) => {

            let value = this.$input.val();
            this.$view.find(".js-autocomplete").remove();

            this.doSearch({
                limit: 20,
                q: value,
                type: "municipality"
            }, (data) => {
                let $autocomplete = $("<div class='js-autocomplete autocomplete'><ul class=''></ul></div>");

                $.map(data.features, (item) => {

                    let formatted = this.addressFormatter(item);
                    let i = Math.floor(Math.random() * Math.floor(100000));

                    let $result = $("<li><a id='" + i + "'>" + formatted.city + ' ' + formatted.postcode + "</a></li>");

                    $autocomplete.find('ul').append($result);

                    this.$view.on("mousedown", "#" + i, (e) => {
                        this.$input.val(formatted.city + ', ' + formatted.postcode);
                        complete = true;
                    });
                    return formatted;

                });

                this.$input.after($autocomplete);
            });
        });

    }

    addressFormatter(item) {
        return {
            postcode: item.properties.postcode,
            city: item.properties.city,
        };
    }

    doSearch(data, successCb = () => {
    }, errorCb = () => {
    }, async = true) {
        let result = null;

        $.ajax({
            method: 'get',
            url: "https://api-adresse.data.gouv.fr/search/",
            data: data,
            dataType: "json",
            async: async,
            timeout: 3000,
            success: (data) => {
                result = successCb(data);
            }
        });
        return result;
    }
}