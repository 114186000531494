import toastr from 'toastr';

export default class Unimplemented {

    constructor($view) {
        this.view = $view;
        this.Bind();
    }

    Bind() {
        let self = this;

        this.view.on('click', function (e) {
            e.preventDefault();
            toastr.error("This function is not implemented yet");
        });
    }
}