import Splide from '@splidejs/splide';

export default class ClientsCarousel extends HTMLDivElement {

    constructor() {
        super();
    }

    connectedCallback() {

        let splide = new Splide(this, {
            type   : 'loop',
            arrows: true,
            pagination: false,
            perPage: 3,
            perMove: 1,
            gap: 40,
            mediaQuery: 'max',
            breakpoints: {
                768: {
                    gap: 12
                },
            }
        });
        splide.mount();
    }

}